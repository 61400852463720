import './index.less';

import Vue from 'vue';
import Component from 'vue-class-component';

import { dictionaryService, IDictionary } from "@/app/apiServices/dictionary/public-api";
import { IdCardValid } from '@/core/utils/IdCard/public-api';
import { ISelectDto } from '@/app/apiServices/baseDto/selectDto';
import { getZSChildBaseInfoPageDto, SaveChildBaseInfo, SaveChildBaseInfoResult, zsChildBaseInfoService, ZSChildBaseInfoPageSaveDto } from '@/app/apiServices/zsChildBaseInfo/public-api';
import { getStepsIcon, filterSelect, isNullOrEmpty, filter } from '@/core/utils/index'
// import moment from 'moment';
import { getStepList, notThisCity_stepsList } from '@/core/utils/enum/notThisCity';
import date from '@/core/utils/date';
import { GradeEnum } from '@/core/utils/enum/grade';
import { SaveParentHoldPageDto } from '@/app/apiServices/parentHoldRegister/public-api';
import { hasRegister } from '@/config/message';
import storage from '@/core/utils/storage';

@Component
export default class childInformationTS extends Vue {
    childBaseInfo = new SaveChildBaseInfo();
    formModel = new SaveChildBaseInfo();
    formRule = {
        gradeCode: [
            { required: true, message: '请选择幼儿报名年级', trigger: 'blur' },
        ],
        regNumber: [
            { required: true, message: '请输入幼儿市登记编号', trigger: 'blur' },
        ],
        gB_SFZ: [
            // { required: true, message: '请输入幼儿证件(居民身份证 证件号码)', trigger: 'blur' },
            {
                required: true,
                validator: (rule: any, value: string, callback: any) => {
                    if (isNullOrEmpty(value)) {
                        callback(new Error(`请输入幼儿证件(${this.formModel.gB_ZJLX} 证件号码)`));
                    }
                    else {
                        callback();
                    }
                },
                trigger: 'blur'
            },
        ],
        isTwins: [
            { required: true, message: '请选择是否双胞胎/多胞胎', trigger: 'blur' },
        ],
        childName: [
            { required: true, message: '请输入幼儿姓名', trigger: 'blur' },
        ],
        sex: [
            { required: true, message: '请选择幼儿性别', trigger: 'blur' },
        ],
        birthday: [
            { type: 'date', required: true, message: '请选择幼儿出生日期', trigger: 'blur' },
        ],
        isHQ: [
            { required: true, message: '请选择是否华侨', trigger: 'blur' },
        ],
        gB_GB_Code: [
            { required: true, message: '请选择国籍/地区', trigger: 'blur' },
        ],
        gB_MZ_Code: [
            { required: true, message: '请选择幼儿民族', trigger: 'blur' },
        ],
        // guarderName: [
        //     { required: true, message: '请输入主要联系的监护人姓名', trigger: 'blur' },
        // ],
        // guarderRelation_Code: [
        //     { required: true, message: '请选择监护人与幼儿关系', trigger: 'blur' },
        // ],
        // guarderZJLX: [
        //     { required: true, message: '请选择监护人证件类型', trigger: 'blur' },
        // ],
        // guarderZJH: [
        //     { required: true, message: '请输入监护人证件号码', trigger: 'blur' },
        //     { type: 'string', min: 18, max: 18, message: '请输入18位监护人证件号码！', trigger: 'blur' },
        //     {
        //         validator: (rule: any, value: string, callback: any) => {
        //             const gbSFZValidator = IdCardValid(value);
        //             if (gbSFZValidator === true) {
        //                 callback();
        //             } else {
        //                 callback(new Error(`${gbSFZValidator}`));
        //             }
        //         },
        //         trigger: 'blur'
        //     }
        // ],
        // guarderPhone: [
        //     { required: true, message: '请输入监护人联系电话', trigger: 'blur' },
        // ],
        residencePermitType: [
            { required: true, message: '请选择居住证办理情况', type: "number", trigger: 'blur' },
            {
                validator: (rule: any, value: number, callback: any) => {
                    if (value !== 0) {
                        callback();
                    } else {
                        callback(new Error('请选择居住证办理情况'));
                    }
                },
                trigger: 'blur'
            }
        ],
        jzZ_ProcessingTime: [
            {
                required: true,
                validator: (rule: any, value: Date, callback: any) => {
                    if (value) {
                        callback();
                    } else {
                        let message = "请选择有效期限开始日期";
                        switch (this.formModel.residencePermitType) {
                            case 1:
                            case 4:
                                message = "请选择有效期限开始日期";
                                break;
                            case 2:
                                message = "请选择登记日期";
                                break;
                            case 3:
                                message = "请选择签发日期";
                                break;
                        }
                        callback(new Error(message));
                    }
                },
                type: "date", trigger: 'change'
            },
            {
                validator: (rule: any, value: Date, callback: any) => {
                    if (this.formModel.jzZ_TermOfValidity == null) {
                        callback();
                    }
                    if (this.formModel.jzZ_TermOfValidity != null && new Date(value) < new Date(this.formModel.jzZ_TermOfValidity)) {
                        callback();
                    } else {
                        let message = "有效期限开始日期不得大于或等于结束日期";
                        switch (this.formModel.residencePermitType) {
                            case 1:
                            case 4:
                                message = "有效期限开始日期不得大于或等于结束日期";
                                break;
                            case 2:
                                message = "登记日期不得大于或等于截止日期";
                                break;
                            case 3:
                                message = "签发日期不得大于或等于有效期限";
                                break;
                        }
                        callback(new Error(message));
                    }
                },
                trigger: 'change'
            }
        ],
        jzZ_TermOfValidity: [
            {
                required: true,
                validator: (rule: any, value: Date, callback: any) => {
                    if (value) {
                        callback();
                    } else {
                        let message = "请选择结束日期";
                        switch (this.formModel.residencePermitType) {
                            case 1:
                            case 4:
                                message = "请选择结束日期";
                                break;
                            case 2:
                                message = "请选择截止日期";
                                break;
                            case 3:
                                message = "请选择有效期限";
                                break;
                        }
                        callback(new Error(message));
                    }
                },
                type: "date", trigger: 'change'
            },
            {
                validator: (rule: any, value: Date, callback: any) => {
                    if (this.formModel.jzZ_ProcessingTime == null) {
                        callback();
                    }
                    if (this.formModel.jzZ_ProcessingTime != null && new Date(value) > new Date(this.formModel.jzZ_ProcessingTime)) {
                        callback();
                    } else {
                        let message = "结束日期不得小于或等于有效期限开始日期";
                        switch (this.formModel.residencePermitType) {
                            case 1:
                            case 4:
                                message = "结束日期不得小于或等于有效期限开始日期";
                                break;
                            case 2:
                                message = "截止日期不得小于或等于登记日期";
                                break;
                            case 3:
                                message = "有效期限不得小于或等于签发日期";
                                break;
                        }
                        callback(new Error(message));
                    }
                },
                trigger: 'change'
            }
        ],
        jzZ_PermitCode: [
            { required: true, message: '请输入海外人才居住证编号', trigger: 'change' },
        ],
        permanentProvinceCode: [
            { required: true, message: '请选择省', trigger: 'change' },
        ],
        permanentCityCode: [
            { required: true, message: '请选择市', trigger: 'change' },
        ],
        permanentDistrictCode: [
            { required: true, message: '请选择区（县）', trigger: 'change' },
        ],
        address: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        residenceStatusCode: [
            { required: true, message: '请选择住所情况', trigger: 'change' },
        ],
        residenceStatus: [
            { required: true, message: '请输入其他住所情况', trigger: 'blur' },
        ],
        relationOfHouseMasterCode: [
            { required: true, message: '请选择产权人与幼儿关系', trigger: 'change' },
        ],
        relationOfHouseMaster: [
            { required: true, message: '请输入其他产权人与幼儿关系', trigger: 'blur' },
        ],
        homeDistrictCode: [
            { required: true, message: '请选择区', trigger: 'change' },
        ],
        homeStreetCode: [
            { required: true, message: '请选择街道', trigger: 'change' },
        ],
        nowJuWeiCode: [
            { required: true, message: '请选择居委', trigger: 'change' },
        ],
        postalCode: [
            { required: true, message: '请输入邮政编码', trigger: 'blur' },
            { type: 'string', min: 6, max: 6, message: '请输入6位邮政编码', trigger: 'blur' },
            {
                validator: (rule: any, value: string, callback: any) => {
                    if (/^[0-9][0-9]{5}$/.test(value)) {
                        callback();
                    } else {
                        callback(new Error('邮政编码格式不正确'));
                    }
                },
                trigger: 'blur'
            }
        ],
        nowAddress: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
        ]
    };
    //年级
    gradeName = "";
    //年段
    gradeListDic: IDictionary[] = [];
    //证件类型
    zjlxListDic: IDictionary[] = [];
    //监护人与幼儿关系
    guarderRelationListDic: IDictionary[] = [];
    //居住证办理情况
    holdCardSelect: ISelectDto[] = [];
    //住所情况
    residenceSelect: ISelectDto[] = [];

    //产权人与幼儿关系
    cqrSelectSelect: ISelectDto[] = [];

    //国家
    countrySelect: ISelectDto[] = [];
    //省市区
    shengshiquSelect: ISelectDto[] = [];

    //幼儿户籍所在地省、市、区
    provinceDic: IDictionary[] = [];
    cityDic: IDictionary[] = [];
    areaDic: IDictionary[] = [];

    //名族
    mzListDic: IDictionary[] = [];

    //居住地区、街道、居委
    homeDistrictDic: IDictionary[] = [];
    homeStreetDic: IDictionary[] = [];
    nowJuWeiDic: IDictionary[] = [];

    stepsList = notThisCity_stepsList;
    activeSteps = 1;

    getStepsIcon(index: number) {
        return getStepsIcon(index, this.activeSteps);
    }

    //获取证件开始时间lable
    get getStartTimeLable() {
        let lable = "有效期限开始日期";
        switch (this.formModel.residencePermitType) {
            case 1:
            case 4:
                lable = "有效期限开始日期";
                break;
            case 2:
                lable = "登记日期";
                break;
            case 3:
                lable = "签发日期";
                break;
        }
        return lable;
    }

    //获取证件截止时间lable
    get getEndTimeLable() {
        let lable = "结束日期";
        switch (this.formModel.residencePermitType) {
            case 1:
            case 4:
                lable = "结束日期";
                break;
            case 2:
                lable = "截止日期";
                break;
            case 3:
                lable = "有效期限";
                break;
        }
        return lable;
    }

    //时间验证
    dateChange(val: any) {
        const { formModel }: any = this.$refs;
        formModel.validateField("jzZ_ProcessingTime");
        formModel.validateField("jzZ_TermOfValidity");
    }

    // 点击确定后返回幼儿报名记录页
    alertDialog(title: string, message: string) {
        this.$dialog.confirm({
            title, message,
            confirmButtonText: "确定",
        }).then(() => {
            this.$router.push("/record");
        });
    }

    //幼儿报名年段
    gradeCodeChange(val: string) {
        switch (val) {
            case 'SZNJ_tb':
                this.formModel.enterGardenType = 1
                break;
            default:
                this.formModel.enterGardenType = 0
                break;
        }
    }

    //居住办理情况change事件  清除时间校验信息
    residencePermitTypeChange(val: number) {
        if (val !== 0) {
            const { formModel }: any = this.$refs;
            this.$nextTick(() => {
                formModel.validateField("jzZ_ProcessingTime");
                formModel.validateField("jzZ_TermOfValidity");
            })
        }
        else {
            this.$dialog.alert({ title: "提示", message: "对不起，您不属于此次幼儿园招生报名范围，无法进行报名！" })
                .then(() => {
                    this.$router.push("/record");
                });
        }
    }

    //户籍 省change事件
    provinceChange(val: string, isManual?: boolean) {
        const isFind = this.provinceDic.find(a => a.code === val);

        if (isFind) {
            this.cityDic = isFind.children;
        } else {
            this.cityDic = [];
        }

        if (isManual == undefined) {
            this.formModel.permanentCityCode = "";
            this.formModel.permanentDistrictCode = "";
        }
    }

    //户籍 市change事件
    cityChange(val: string, isManual?: boolean) {
        const isFind = this.cityDic.find(a => a.code === val);
        if (isFind) {
            this.areaDic = isFind.children;
        } else {
            this.areaDic = [];
        }

        if (isManual == undefined) {
            this.formModel.permanentDistrictCode = "";
        }
    }

    //居住地 区Change事件
    homeDistrictChange(val: string) {
        const isFind = this.homeDistrictDic.find(a => a.code === val);
        if (isFind) {
            this.homeStreetDic = isFind.children;
        } else {
            this.homeStreetDic = [];
        }
    }

    //居住地 街道Change事件
    homeStreetChange(val: string, isManual?: boolean) {
        const isFind = this.homeStreetDic.find(a => a.code === val);
        if (isFind) {
            this.nowJuWeiDic = isFind.children;
        } else {
            this.nowJuWeiDic = [];
        }
        if (isManual === undefined) {
            this.formModel.nowJuWeiCode = "";
        }
    }

    //居住地 居委Change事件
    nowJuWeiChange(val: string) {
        const isFind = this.nowJuWeiDic.find(a => a.code === val);
        if (isFind) {
            this.formModel.nowJuWeiCode = val;
        } else {
            this.formModel.nowJuWeiCode = "";
        }
    }

    // 根据code 获得 name
    formModelCodeToName() {
        //国家
        this.formModel.gB_GB = filterSelect(this.countrySelect, this.formModel.gB_GB_Code);

        //名族
        this.formModel.gB_MZ = filter(this.mzListDic, this.formModel.gB_MZ_Code);

        //居住证办理情况
        this.formModel.residencePermitTypeDec = filterSelect(this.holdCardSelect, this.formModel.residencePermitType);

        //户籍省市区
        this.formModel.permanentProvince = filter(this.provinceDic, this.formModel.permanentProvinceCode);
        this.formModel.permanentCity = filter(this.cityDic, this.formModel.permanentCityCode);
        this.formModel.permanentDistrict = filter(this.areaDic, this.formModel.permanentDistrictCode);

        //住所情况(非其他)
        if (this.residenceSelect.length > 0 && this.formModel.residenceStatusCode !== 'ZSQK_qt') {
            this.formModel.residenceStatus = (this.residenceSelect.find(a => a.optionValue === this.formModel.residenceStatusCode) as ISelectDto)?.optionText;
        }

        //产权人与幼儿关系(非其他)
        if (this.formModel.relationOfHouseMasterCode !== 'CQRYYEGX_qt') {
            this.formModel.relationOfHouseMaster = filterSelect(this.cqrSelectSelect, this.formModel.relationOfHouseMasterCode);
        }

        //居住地区、街道、居委
        this.formModel.homeDistrict = filter(this.homeDistrictDic, this.formModel.homeDistrictCode);
        this.formModel.homeStreet = filter(this.homeStreetDic, this.formModel.homeStreetCode);
        this.formModel.nowJuWei = filter(this.nowJuWeiDic, this.formModel.nowJuWeiCode);
    }

    // 初始化 formModel
    initializationFormModel() {
        const { birthday, cardNumber, cardType, cardTypeName, gradeCode, regNumber, childType }: any = storage.GET_RegistrationInfo();
        this.gradeName = filter(GradeEnum, gradeCode);

        this.formModel.gradeCode = gradeCode;
        this.formModel.regNumber = regNumber;
        this.formModel.gB_ZJLX = cardTypeName;
        this.formModel.gB_ZJLX_Code = cardType;
        this.formModel.gB_SFZ = cardNumber;
        this.formModel.birthday = birthday;
        this.formModel.type = Number(childType);

        //设置性别
        if (cardType == 'ZJLX_sfz') {
            this.formModel.setSexByGB_SFZ();
        }

        //居住区默认黄浦
        this.formModel.homeDistrictCode = "huangpu";

        // //如果证件类型不为身份证，国籍/地区默认第一个
        // if (this.formModel.gB_ZJLX_Code != 'ZJLX_sfz') {
        //     this.formModel.gB_GB_Code = this.formModel.gB_GB_Code ? this.formModel.gB_GB_Code : this.countrySelect[0]?.optionValue;
        // }

        //入园方式  默认为无
        this.formModel.enterGardenType = 0;
        // //民族 默认汉族
        // this.formModel.gB_MZ_Code = this.formModel.gB_MZ_Code == null ? 'mz_01' : this.formModel.gB_MZ_Code;
        // //是否是华侨 默认否
        // this.formModel.isHQ = this.formModel.isHQ == null ? '否' : this.formModel.isHQ;
        // //住所情况  默认租赁
        // this.formModel.residenceStatusCode = this.formModel.residenceStatusCode != null ? this.formModel.residenceStatusCode : "ZSQK_zl";
    }

    // 加载表单数据
    viewLoad(): void {
        const { childName, birthday, cardNumber, cardType, gradeCode, childType }: any = storage.GET_RegistrationInfo();
        const params = {
            childName: childName,
            birthday: birthday,
            cardNumber: cardNumber,
            cardType: cardType,
            gradeCode: gradeCode,
            childType: childType
        }
        zsChildBaseInfoService.getZSChildBaseInfoPage(params)
            .then((res: getZSChildBaseInfoPageDto) => {
                // 可能为 null 不存在数据
                if (res) {
                    if (res.verifyResult?.success == false) {
                        this.$toast({
                            message: res.verifyResult?.message,
                            position: 'top'
                        });
                        return;
                    }
                    this.formModel = Object.assign(this.formModel, res);

                    this.holdCardSelect = res.holdCardSelect || [];
                    this.residenceSelect = res.residenceSelect || [];
                    this.provinceDic = res.shengshiquSelect || [];
                    this.countrySelect = res.countrySelect || [];
                    this.cqrSelectSelect = res.cqrSelectSelect || [];
                }
                this.initializationFormModel();

                // 加载 居住地 区dic
                this.homeDistrictChange('huangpu');
                //加载 居住地 街道dic
                this.homeStreetChange(this.formModel.homeStreetCode, true);
                //加载 居住地 居委
                this.nowJuWeiChange(this.formModel.nowJuWeiCode);

                // 加载 户籍 省dic
                this.provinceChange(this.formModel.permanentProvinceCode, true);
                // 加载 户籍 市dic
                this.cityChange(this.formModel.permanentCityCode, true);

                //重新加载进度条
                this.stepsList = getStepList();
            });
    }

    // 下一步点击
    nextOnClick(): void {
        //为了过表单data数据验证
        this.formModel.birthday = new Date(date.formatD((<Date>this.formModel.birthday).toString()));

        const { formModel }: any = this.$refs;
        formModel.validate((valid: boolean) => {
            if (valid) {

                //根据code获取名称
                this.formModelCodeToName();

                //有海外人才居住证或有上海市居住证，申请积分且达标的默认打分表为满分
                const parentInfo = storage.GET_ParentInfomation();
                this.formModel.isFullScore = parentInfo.parentsAllLocal == 0 && (parentInfo.isHoldResidencePermit == 3 ||
                    (parentInfo.isHoldResidencePermit == 1 &&
                        parentInfo.isApplyScore == 1 &&
                        parentInfo.isStandardScore == 1));

                //处理8小时时差问题
                if (this.formModel.residencePermitType !== 0) {
                    this.formModel.jzZ_ProcessingTime = new Date(date.formatD((<Date>this.formModel.jzZ_ProcessingTime).toString()));
                    this.formModel.jzZ_TermOfValidity = new Date(date.formatD((<Date>this.formModel.jzZ_TermOfValidity).toString()));
                }
                // 确定下一步
                const nextFun = () => {
                    const input = Object.assign(new ZSChildBaseInfoPageSaveDto(), this.formModel);

                    zsChildBaseInfoService.saveZSChildBaseInfoPage(input)
                        .then((res: SaveChildBaseInfoResult) => {
                            if (res.verifyResult.success === true) {
                                this.formModel.id = res.data;
                                this.$store.dispatch('ACTION_ChildBaseInfo', this.formModel);

                                //更新部分登记信息
                                const registrationInfo = storage.GET_RegistrationInfo();
                                registrationInfo.childId = this.formModel.id;
                                registrationInfo.enterGardenType = this.formModel.enterGardenType;
                                this.$store.dispatch('ACTION_RegistrationInfo', registrationInfo);
                                this.$router.push(`/notThisCity/familyMemberInformation`);
                                // this.$router.push(`/notThisCity/familyMemberInformation/${res.data}/${this.formModel.enterGardenType}/${this.formModel.gradeCode}`);
                            }
                            else {
                                if (res.verifyResult.exceptionCode == "GOHOME") {
                                    this.$dialog.alert({
                                        message: hasRegister,
                                    }).then(() => {
                                        this.$router.push("/record");
                                    });
                                }
                                else {
                                    this.$toast({
                                        message: res.verifyResult.message,
                                        position: 'top'
                                    });
                                }
                            }
                        })
                }

                nextFun();
            }
            else {
                this.$toast({
                    message: '填写内容存在错误请仔细核对！',
                    position: 'top'
                });
            }
        });
    }

    // 加载字典
    async viewLoadDicArray() {
        //名族
        this.mzListDic = await dictionaryService.getMZList();
        //居住地区、街道、居委
        this.homeDistrictDic = await dictionaryService.getJuweiList('');

        this.viewLoad();

    }

    mounted(): void {
        this.viewLoadDicArray();
    }
}